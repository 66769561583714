<template>
  <div class="edit-user-wrapper">
    <!-- <div class="pb-2" style="background: #fff">
      <div style="margin: 10px;">
        <span style="color:#cad4dd; font-family:Muli-Regular; font-size:12px"
          >Users ></span
        >
      </div>
      <span
        id="user"
        style="color:#1b1c1d; margin: 10px; font-size:19px; font-family:Muli-Regular; font-weight:bold"
        >USERS</span
      >
    </div> -->
    <p class="mb-0 ml-2 d-flex justify-content-between" style="font-size:16px; color:#1b1c1d;font-weight:600">
        Edit User
        <span class="mr-2"> 
            <img  @click="goToUserHome"  style="cursor: pointer;" src="../../assets/SVG/Back.svg" width="60px">
        </span>
      </p>
    <b-card class="card m-2 edit-user">
      <p class="mb-0" style="font-size:16px; color:#1b1c1d;font-weight:600">
        Edit User
      </p>
      <div class="row justify-content-around">
        <w-text-input
          :fieldType="'text'"
          :labelName="'First Name'"
          :validation="'required|regex_userName'"
          :className="'w-100 col-md-4'"
          :placeholder="'First Name'"
          :disabled="false"
          :defaultValue="firstName"
          :fieldName="'first name'"
          :labelStyle="'register-label'"
          @textInputChange="enterFirstName($event)"
        />
        <w-text-input
          :fieldType="'text'"
          :labelName="'Last Name'"
          :validation="'required|regex_userName'"
          :className="'w-100 col-md-4'"
          :placeholder="'last name'"
          :disabled="false"
          :defaultValue="lastName"
          :fieldName="'Last Name'"
          :labelStyle="'register-label'"
          @textInputChange="enterLastName($event)"
        />
        <w-text-input
          :fieldType="'text'"
          :labelName="'Email ID'"
          :validation="'required|regex_Email'"
          :className="'w-100 col-md-4'"
          :placeholder="'Email-Id'"
          :disabled="false"
          :defaultValue="emailId"
          :fieldName="'email'"
          :labelStyle="'register-label'"
          @textInputChange="enterEmailId($event)"
        />
      </div>

      <div class="row role-dropdown">
        <div class="col-lg-4">
          <label class="p-0 edituser_label">Status</label>
          <w-dropdown
            :label-text="''"
            :selectedValue="selectedStatusListValue"
            :selectOption="statusListOptions"
            @selectedOption="onSelectedUserStatus($event)"
          />
        </div>
        <div class="col-lg-4">
          <w-text-input
            :fieldType="'text'"
            :labelName="'Mobile Number'"
            :validation="'required|regex_mobile_number'"
            :className="'w-100'"
            :placeholder="'Enter Mobile No.'"
            :disabled="false"
            :defaultValue="mobileNumber"
            :fieldName="'email'"
            :labelStyle="'register-label'"
            @textInputChange="enterMobileNumber($event)"
          />
        </div>
        <!-- <div class="col-lg-4">
          <label class="p-0 edituser_label"
            >Roles <span class="warning_icon">!</span>
          </label>
          <TreeDropdown
            :rootOptions="roleOptions"
            :selectedValue="roleSelected"
            @selectedField="getSelectedRoles($event)"
          />
        </div> -->
      </div>
      <div class="row mt-3 d-flex justify-content-end btn-groups">
        <w-button-input @buttonClicked="showConfirmModal" :label="'Save'" :buttonStyle="'generate-btn'" :buttonClass="'generate-btn'" />
        <w-button-input @buttonClicked="goToUserHome" :label="'Cancel'" :buttonStyle="'generate-clear'" :buttonClass="'generate-btn'" />
      </div>
    </b-card>
    <!-- error handling -->
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
  </div>
</template>
<script>
import TreeDropdown from "../../widgets/TreeDropdown";
import TextInput from "../../widgets/TextInput.vue";
import Dropdown from "../../widgets/Dropdown.vue";
import Modal from "../../widgets/ModalWidget";
import ButtonInput  from "../../widgets/InputButton.vue";
import {
  getUserStatus,
  // getUserRoles,
  userDetailsById,
  updateUserDetail,
} from "../../Service/UserService";
export default {
  components: {
    TreeDropdown,
    "w-text-input": TextInput,
    "w-dropdown": Dropdown,
    Modal,
    "w-button-input":ButtonInput
  },

  props: ["userData"],

  data() {
    return {
      companyID: sessionStorage.getItem("companyIds"),
      // for error modal
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,

      // editUserName: "",
      // editUserStatus: "",
      // editUserRole: "",
      // userDetailsDto:{},
      selectedStatusListValue: "",
      statusListOptions: [],
      // selectedRoleListValue: "",
      // roleListOptions: [],
      // roleOptions: [],
      // roleSelected: [],
      // selectedRole: {},

      firstName: "",
      lastName: "",
      emailId: "",
      mobileNumber: "",
      existROLE:[],
    };
  },
  created() {
    this.getUserStatus();
    // this.getUserRoles();
    this.individualUserDetails()
  },
  mounted() {
    // this.editUserName = this.$route.query["user-name"];
    // this.editUserStatus = this.$route.query["user-status"];
    // this.editUserRole = this.$route.query["user-role"];
  },

  methods: {
    getUserStatus() {
      this.statusListOptions = [];
      var status = [];

      getUserStatus()
        .then((res) => {
          status = res || [];
          status.map((element) => {
            this.statusListOptions.push({
              value: element,
              text: element,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // getUserRoles() {
    //   // this.roleListOptions = [];
    //   // var roles = [];
    //   // this.roleListOptions.push({
    //   //   value: "axs",
    //   //   text: this.selectedRoleListValue,
    //   // })

    //   getUserRoles()
    //     .then((res) => {
    //       let roles = [];
    //       roles = res || [];
    //       // roles.map((element) => {
    //       //   this.roleListOptions.push({
    //       //     value: element.roleName,
    //       //     text: element.roleDisplayName,
    //       //   });
    //       // });
    //       roles.forEach((element) => {
    //         this.roleOptions.push({
    //           id: element.roleName,
    //           label: element.roleDisplayName,
    //         });
    //       });
    //       this.individualUserDetails();
    //     })
    //     .catch((err) => {
    //       this.alert = true;
    //       this.alertMsg = err.message;
    //       this.alertType = "Failure";
    //       this.isConfirmAlert = false;
    //     });
    // },

    individualUserDetails() {
      console.log("user id ", this.userData);
      userDetailsById(this.userData.id)
        .then((res) => {
          // console.log("edit user => ", res);
          this.firstName = res.firstName;
          this.lastName = res.lastName;
          this.emailId = res.email;
          this.mobileNumber = res.mobileNumber;

          this.existROLE=[];
          res.roles.forEach((el) => {
             this.existROLE.push( el.roleName )
          })
          // this.selectedRoleListValue = res.roles[0].roleDisplayName;
          // this.roleSelected.push(res.roles[0].roleName);
          if (res.active == true) {
            this.selectedStatusListValue = "ACTIVE";
          } else {
            this.selectedStatusListValue = "INACTIVE";
          }

          // this.selectedRole =
          //   this.roleListOptions.find(
          //     (e) => e.text === this.selectedRoleListValue
          //   ) || {};
          // console.log("role", this.selectedRole, this.roleListOptions);
          
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    updateUserDetail() {
      let updateData = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.emailId,
        id: this.userData.id,
        mobileNumber: this.mobileNumber,
        password: this.userData.password,
        // roleId: this.selectedRoleListValue,
        // roleNames: this.roleSelected,
        roleNames:this.existROLE
        
        // userStatus: this.selectedStatusListValue,
      };
      updateUserDetail(updateData, this.companyID)
        .then((res) => {
          console.log("update user data :- ", res);
          this.goToUserHome();
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    onSelectedUserStatus(event) {
      this.selectedStatusListValue = event;
    },
    // getUserRoleList(event) {
    //   this.selectedRoleListValue = event;
    // },
    // getSelectedRoles(event) {
    //   this.roleSelected = event;
    // },
    enterFirstName(event) {
      this.firstName = event;
    },
    enterLastName(event) {
      this.lastName = event;
    },
    enterEmailId(event) {
      this.emailId = event;
    },
    enterMobileNumber(event) {
      this.mobileNumber = event;
    },

    goToUserHome() {
      this.$router.push({ path: "/user" });
    },

    // close error modal
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    showConfirmModal() {
      this.alertMsg = "Are you sure want to update ?";
      this.alert = true;
      this.alertType = "";
      this.isConfirmAlert = true;
    },
    confirmOk() {
      this.updateUserDetail();
    },
  },
};
</script>

<style>
.edit-user .custom-select,
.edit-user .form-control {
  height: 37px !important;
}
.edit-user-wrapper{
  padding: 0rem 3% 0% 4%;
}
.edit-user{
  padding: 1rem;
}
.role-dropdown #app{
  display: flex !important;
}
.edituser_label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  /* font-weight: 600; */
  color: #767676;
}
.edituser_label .warning_icon {
  border: 1px solid #f2943a;
  border-radius: 50%;
  font-size: 7px;
  padding: 1px 4px;
  color: #f2943a;
  vertical-align: middle;
  cursor: pointer;
}
.save-cancel_btn {
  border-radius: 2px;
  border: solid 1px #2e8cd3;
  background-color: #2e8cd3;
  color: #fff;
  padding: 4px 50px;
  font-size: 16px;
  font-family: "proxima-nova", sans-serif !important;
}

@media screen and (min-width:800px) and (max-width:991px) {
  .edit-user-wrapper{
    padding: 0rem 3% 0% 7%;
  }
}
@media screen and (max-width:425px) {
  .btn-groups{
    flex-wrap: nowrap;
    justify-content: center;
  }
  .btn-groups button{
    max-width: 7rem !important;
  }
}
</style>
